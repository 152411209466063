import { AlertController } from '@ionic/angular';
import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OpenModalGuardService } from '../open-modal-guard/open-modal-guard.service';

@Injectable({
  providedIn: 'root',
})
export class FunnelGuardService implements CanDeactivate<any> {
  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
  serviceCreated = false;

  constructor(
    private alertController: AlertController,
    private openModalGuardService: OpenModalGuardService,
  ) {}

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.openModalGuardService.modalOpen) {
      return false;
    } else if (this.serviceCreated) {
      return true;
    } else {
      this.presentAlertConfirm();
      return this.navigateAwaySelection$;
    }
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: 'No te vayas',
      message: `Aún no has publicado la oferta ¿Deseas continuar?`,
      cssClass: 'alert-modal',
      buttons: [
        {
          text: 'cancelar publicación',
          role: 'cancel',
          handler: () => {
            this.navigateAwaySelection$.next(true);
          },
        },
        {
          text: 'continuar',
          handler: () => {
            this.navigateAwaySelection$.next(false);
          },
        },
      ],
    });

    await alert.present();
  }
}
