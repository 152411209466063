<ion-content>
  <div class="container-card-button">
    <div class="bot-avatar">
      <img
        src="/assets/imgs/onboarding/Bot-Job-City.png"
        alt="" />
    </div>
    <ion-fab
      vertical="top"
      horizontal="end"
      slot="fixed">
      <ion-fab-button
        size="small"
        color="transparent"
        class="close-fab-button"
        (click)="closeModal('', '')"
        tooltip="Click para cerrar">
        <ion-icon
          name="chevron-down-outline"
          color="medium"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ion-card>
      <ion-card-header>
        <ion-card-title class="primary-text"
          >Bienvenido a Job City</ion-card-title
        >
        <ion-card-subtitle
          >Vamos a optimizar tu perfil, para que saques el máximo
          provecho</ion-card-subtitle
        >
        <div
          class="d-flex align-items-center"
          *ngIf="user && user.onboardingType">
          <h5>{{ calculateProgress() }}%</h5>
          <ion-progress-bar
            [value]="calculateProgress() / 100"></ion-progress-bar>
        </div>
      </ion-card-header>

      <ion-card-content
        *ngIf="user && !user.onboardingType"
        class="onboarding-type-wrapper">
        <h4 class="black-text bold-text">Indícanos que deseas hacer:</h4>
        <ion-list lines="none">
          <ion-item
            detail
            button
            (click)="selectOnboardingType('person')">
            <ion-label>Buscar empleo (persona)</ion-label>
            <ion-icon
              slot="start"
              name="ellipse-outline"
              class="check-icon"></ion-icon>
          </ion-item>
          <ion-item
            detail
            button
            (click)="selectOnboardingType('company')">
            <ion-label>Buscar talento humano (empresa)</ion-label>
            <ion-icon
              slot="start"
              name="ellipse-outline"
              class="check-icon"></ion-icon>
          </ion-item>
        </ion-list>
      </ion-card-content>

      <ion-card-content
        *ngIf="user && user.onboardingType && user.onboardingType === 'person'">
        <ion-list lines="none">
          <ion-item
            detail
            button
            (click)="closeModal('personas', 'person-minibio')"
            [disabled]="validateStatus('minibio')">
            <ion-label>Crear Mini Bio</ion-label>
            <ion-icon
              slot="start"
              name="ellipse-outline"
              *ngIf="!validateStatus('minibio')"
              class="check-icon"></ion-icon>
            <ion-icon
              slot="start"
              color="primary"
              name="checkmark-circle"
              *ngIf="validateStatus('minibio')"
              class="check-icon"></ion-icon>
          </ion-item>
          <ion-item
            detail
            button
            (click)="closeModal('personas', 'person-searcher')"
            [disabled]="validateStatus('searcher')">
            <ion-label>Acceder a ofertas</ion-label>
            <ion-icon
              slot="start"
              name="ellipse-outline"
              *ngIf="!validateStatus('searcher')"
              class="check-icon"></ion-icon>
            <ion-icon
              slot="start"
              color="primary"
              name="checkmark-circle"
              *ngIf="validateStatus('searcher')"
              class="check-icon"></ion-icon>
          </ion-item>
          <ion-item
            detail
            button
            (click)="closeModal('personas', 'person-findme')"
            [disabled]="validateStatus('findme')">
            <ion-label>Activar Find Me</ion-label>
            <ion-icon
              slot="start"
              name="ellipse-outline"
              *ngIf="!validateStatus('findme')"
              class="check-icon"></ion-icon>
            <ion-icon
              slot="start"
              color="primary"
              name="checkmark-circle"
              *ngIf="validateStatus('findme')"
              class="check-icon"></ion-icon>
          </ion-item>
          <ion-item
            detail
            button
            (click)="closeModal('personas', 'person-route')"
            [disabled]="validateStatus('route')">
            <ion-label>Ruta de Aceleramiento</ion-label>
            <ion-icon
              slot="start"
              name="ellipse-outline"
              *ngIf="!validateStatus('route')"
              class="check-icon"></ion-icon>
            <ion-icon
              slot="start"
              color="primary"
              name="checkmark-circle"
              *ngIf="validateStatus('route')"
              class="check-icon"></ion-icon>
          </ion-item>
        </ion-list>
      </ion-card-content>
      <!-- Empresas -->
      <ion-card-content
        *ngIf="user && user.onboardingType && user.onboardingType === 'company'">
        <ion-list lines="none">
          <ion-item
            detail
            button
            (click)="closeModal('empresas', 'company-crearEmpresa')"
            [disabled]="validateStatus('create-company')">
            <ion-label>Crear Empresa</ion-label>
            <ion-icon
              slot="start"
              name="ellipse-outline"
              *ngIf="!validateStatus('create-company')"
              class="check-icon"></ion-icon>
            <ion-icon
              slot="start"
              color="primary"
              name="checkmark-circle"
              *ngIf="validateStatus('create-company')"
              class="check-icon"></ion-icon>
          </ion-item>
          <ion-item
            detail
            button
            (click)="closeModal('empresas', 'company-completProfile')"
            [disabled]="validateStatus('show-companies') || !validateStatus('create-company')">
            <ion-label>Completar perfil de empresa</ion-label>
            <ion-icon
              slot="start"
              name="ellipse-outline"
              *ngIf="!validateStatus('show-companies')"
              class="check-icon"></ion-icon>
            <ion-icon
              slot="start"
              color="primary"
              name="checkmark-circle"
              *ngIf="validateStatus('show-companies')"
              class="check-icon"></ion-icon>
          </ion-item>
          <ion-item
            detail
            button
            (click)="closeModal('empresas', 'company-publicarOferta')"
            [disabled]="validateStatus('offers') || !validateStatus('create-company')">
            <ion-label>Publicar Oferta</ion-label>
            <ion-icon
              slot="start"
              name="ellipse-outline"
              *ngIf="!validateStatus('offers')"
              class="check-icon"></ion-icon>
            <ion-icon
              slot="start"
              color="primary"
              name="checkmark-circle"
              *ngIf="validateStatus('offers')"
              class="check-icon"></ion-icon>
          </ion-item>
          <ion-item
            detail
            button
            (click)="closeModal('empresas', 'company-gestionOferta')"
            [disabled]="validateStatus('show-offers') || !validateStatus('create-company')">
            <ion-label>Administrar Ofertas</ion-label>
            <ion-icon
              slot="start"
              name="ellipse-outline"
              *ngIf="!validateStatus('show-offers')"
              class="check-icon"></ion-icon>
            <ion-icon
              slot="start"
              color="primary"
              name="checkmark-circle"
              *ngIf="validateStatus('show-offers')"
              class="check-icon"></ion-icon>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>

    <div class="ion-text-center">
      <ion-button
        shape="round"
        expand="full"
        class="padding-button">
        <ion-icon
          name="checkmark-circle-outline"
          class="size-icon"></ion-icon>
        <span>¡Configuremos tu perfil!</span>
      </ion-button>
    </div>
  </div>
</ion-content>
