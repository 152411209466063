<ion-content class="ion-padding">
  <ion-grid class="confirm-wrap">
    <ion-row
      class="confirm-row ion-align-items-center ion-justify-content-center">
      <!-- imagen -->
      <ion-col
        size="12"
        size-md="6"
        class="ion-text-center">
        <ion-img
          class="picture"
          [src]="image"></ion-img>
      </ion-col>
      <!-- contenido -->
      <ion-col
        size="12"
        size-md="6"
        class="ion-text-center">
        <!-- html -->
        <div [innerHtml]="html"></div>
        <!-- botones -->
        <div class="buttons-wrapper">
          <ion-button
            *ngFor="let button of buttons"
            (click)="actionModal(button)"
            [color]="button.color"
            [fill]="button.fill">
            {{ button.title }}
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
