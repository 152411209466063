import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { EventEmitter, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import * as moment from 'moment-timezone';
import { first } from 'rxjs/operators';
import { ErrorMonitoringService } from '../error-monitoring/error-monitoring.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ModalController } from '@ionic/angular';
import { SalesModalComponent } from 'src/app/components/sales-modal/sales-modal.component';
import { OpenModalGuardService } from '../open-modal-guard/open-modal-guard.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  orderBy,
  updateDoc,
  DocumentData,
  DocumentReference,
} from '@angular/fire/firestore';
import {
  DuplicatedOfferType,
  OfferType,
  OffersSearchKeywordsType,
  OffersSearchType,
} from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  /** event emitter para avisar que un usuario se postulo o
   * rechazo a una oferta */
  public reloadOffers: EventEmitter<void> = new EventEmitter<void>();
  /** nombre coleccion ofertas */
  private offersCollectionName = 'offers';
  /** referencia a la coleccion de ofertas */
  private offersRef = collection(this.firestore, this.offersCollectionName);
  /** nombre coleccion de busqueda de ofertas */
  private offersSearchCollectionName = 'offersSearch';
  /** referencia a la coleccion de busqueda ofertas */
  private offersSearchRef = collection(
    this.firestore,
    this.offersSearchCollectionName,
  );
  /** nombre coleccion depalabras clave en la busqueda de ofertas */
  private offersSearchKeywordCollectionName = 'offersSearchKeywords';
  /** referencia a la coleccion de busqueda ofertas */
  private offersSearchKeywordRef = collection(
    this.firestore,
    this.offersSearchKeywordCollectionName,
  );
  /** referencia a la coleccion de ofertas duplicadas */
  private duplicatedOffersRef = collection(this.firestore, 'duplicatedOffers');
  /** datos de oferta seleccionada */
  private selectedOffer: any;
  /* contiene el objeto para inicializar las estidisticas de una oferta */
  private newStatsData = {
    pageViewCounter: 1,
    pageView: [
      {
        date: moment().tz('America/Bogota').format(),
      },
    ],
    linkClickCounter: 0,
    linkClick: [],
    postulationCounter: 0,
    postulation: [],
    rejectionCounter: 0,
    rejection: [],
    shareCounter: 0,
    share: [],
  };
  /** Contiene los labels para los tipos de salario */
  salaryMode = {
    rangeSalary: {
      labelInput: 'desde',
      labelResume: 'Rango salarial',
    },
    fixedSalary: {
      labelInput: 'Salario a pagar',
      labelResume: 'Salario',
    },
  };

  constructor(
    private angularFirestore: AngularFirestore,
    private errorMonitoringService: ErrorMonitoringService,
    private angularFireFunctions: AngularFireFunctions,
    private firebaseAnalytics: AngularFireAnalytics,
    private modalController: ModalController,
    private openModalGuardService: OpenModalGuardService,
    private sanitizer: DomSanitizer,
    private firestore: Firestore,
  ) {}

  /** agrega una oferta */
  addOffer(offer: OfferType) {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName)
      .add(offer);
  }

  /** actualiza una oferta */
  updateOffer(offerId: string, offerData: OfferType) {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName)
      .doc(offerId)
      .update(offerData);
  }

  /** elimina una oferta */
  deleteOffer(offerId: string) {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName)
      .doc(offerId)
      .delete();
  }

  /** obtiene todas las ofertas por email */
  getOffersByEmail(email: string, oldDays?: number) {
    let q = query(this.offersRef, where('email', '==', email));
    if (oldDays) {
      q = query(
        q,
        where('date', '>=', moment().subtract(oldDays, 'days').format()),
      );
    }
    return getDocs(q);
  }

  /** obtiene todas las ofertas por link */
  getOffersByLink(link: string, oldDays?: number) {
    let q = query(this.offersRef, where('link', '==', link));
    if (oldDays) {
      q = query(
        q,
        where('date', '>=', moment().subtract(oldDays, 'days').format()),
      );
    }
    return getDocs(q);
  }

  /** agrega una registro de oferta duplicada */
  addDuplicatedOffer(data: DuplicatedOfferType) {
    return addDoc(this.duplicatedOffersRef, data);
  }

  /** republica la oferta */
  republishOffer(offer: { offer: OfferType; offerId: string }) {
    const callable = this.angularFireFunctions.httpsCallable('republishOffer');
    return callable(offer);
  }

  /** obtiene todas las ofertas de una empresa */
  getOffersByCompany(company: string) {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName, (ref) =>
        ref.where('admin', '==', true).where('company.id', '==', company),
      )
      .get();
  }

  /** obtiene las primeras 15 ofertas de una empresa */
  getOffersByCompanyAndStatus(status: string, company: string) {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName, (ref) =>
        ref
          .where('admin', '==', true)
          .where('company.id', '==', company)
          .where('status', '==', status)
          .orderBy('date', 'desc')
          .limit(15),
      )
      .snapshotChanges()
      .pipe(
        map((offers) => {
          return offers.map((offer) => {
            const docRef = offer.payload.doc;
            const data = offer.payload.doc.data();
            const id = offer.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
      );
  }

  /** obtiene mas ofertas de una empresa */
  getMoreOffersByCompanyAndStatus(
    status: string,
    company: string,
    lastDoc: firebase.firestore.DocumentSnapshot,
  ) {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName, (ref) =>
        ref
          .where('admin', '==', true)
          .where('company.id', '==', company)
          .where('status', '==', status)
          .orderBy('date', 'desc')
          .limit(15)
          .startAfter(lastDoc),
      )
      .snapshotChanges()
      .pipe(
        map((offers) => {
          return offers.map((offer) => {
            const docRef = offer.payload.doc;
            const data = offer.payload.doc.data();
            const id = offer.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
      );
  }

  /** consulta todas las ofertas publicadas de un usuario en una empresa */
  getOffersByUserAndCompany(
    user: string,
    status: string,
    company: string,
  ): Observable<any[]> {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName, (ref) =>
        ref
          .where('user.id', '==', user)
          .where('admin', '==', true)
          .where('status', '==', status)
          .where('company.id', '==', company)
          .orderBy('date', 'desc')
          .limit(15),
      )
      .snapshotChanges()
      .pipe(
        map((offers) => {
          return offers.map((offer) => {
            const docRef = offer.payload.doc;
            const data = offer.payload.doc.data();
            const id = offer.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
      );
  }

  /** obtiene mas ofertas desde un docRef especifico */
  getMoreOffersByUserAndCompany(
    user: string,
    status: string,
    company: string,
    lastDoc: firebase.firestore.DocumentSnapshot,
  ): Observable<any[]> {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName, (ref) =>
        ref
          .where('user.id', '==', user)
          .where('admin', '==', true)
          .where('status', '==', status)
          .where('company.id', '==', company)
          .orderBy('date', 'desc')
          .limit(5)
          .startAfter(lastDoc),
      )
      .snapshotChanges()
      .pipe(
        map((offers) => {
          return offers.map((offer) => {
            const docRef = offer.payload.doc;
            const data = offer.payload.doc.data();
            const id = offer.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
      );
  }

  /** consulta todas las ofertas publicadas de un usuario en una empresa */
  getOffersByUserAndStatus(user: string, status: string): Observable<any[]> {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName, (ref) =>
        ref
          .where('user.id', '==', user)
          .where('admin', '==', true)
          .where('status', '==', status),
      )
      .snapshotChanges()
      .pipe(
        map((offers) => {
          return offers.map((offer) => {
            const docRef = offer.payload.doc;
            const data = offer.payload.doc.data();
            const id = offer.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
      );
  }

  /** consulta todas las ofertas notificadas a un usuario */
  getSubscriptionOffers(user: string): Observable<any[]> {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName, (ref) =>
        ref
          .where('subscription.uids', 'array-contains', user)
          .orderBy('date', 'desc'),
      )
      .snapshotChanges()
      .pipe(
        map((offers) => {
          return offers.map((offer) => {
            const docRef = offer.payload.doc;
            const data = offer.payload.doc.data();
            const id = offer.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
      );
  }

  /** consulta el detalle de una oferta */
  getOffer(offerId: string) {
    return this.angularFirestore
      .collection<any>(this.offersCollectionName)
      .doc(offerId)
      .get();
  }

  /** selecciona una oferta */
  setSelectedOffer(offer: any) {
    this.selectedOffer = offer;
  }

  /** obtiene todos los tipos de contrato */
  getContractTypes() {
    return this.angularFirestore
      .collection<any>('contractTypes', (ref) => ref.orderBy('name'))
      .get();
  }

  /** obtiene todos los tipos de jornada */
  getJourneyTypes() {
    return this.angularFirestore
      .collection<any>('journeyTypes', (ref) => ref.orderBy('name'))
      .get();
  }

  /** obtiene todos los tipos de experiencia */
  getExperienceTypes() {
    return this.angularFirestore
      .collection<any>('experienceTypes', (ref) => ref.orderBy('name'))
      .get();
  }

  /** obtiene todos los grupos de telegram */
  getTelegramGroups() {
    return this.angularFirestore
      .collection<any>('telegramGroups', (ref) => ref.orderBy('name'))
      .snapshotChanges()
      .pipe(
        map((groups) => {
          return groups.map((group) => {
            const docRef = group.payload.doc;
            const data = group.payload.doc.data();
            const id = group.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
        first(),
      )
      .toPromise();
  }

  /** consulta datos de estadisticas sobre la oferta */
  getOfferStats(id: string) {
    return this.angularFirestore.collection<any>('offerStats').doc(id).get();
  }

  /** registra datos de estadisticas sobre una oferta */
  logOfferStat(id: string, data: any) {
    return this.angularFirestore
      .collection<any>('offerStats')
      .doc(id)
      .set(data);
  }

  /** logica para registrar una estadistica en la oferta */
  logStat(statType: string, id: string) {
    console.log('entra a stats');
    this.getOfferStats(id)
      .toPromise()
      .then((offerLink) => {
        if (!offerLink.exists) {
          // actualiza la bd
          this.logOfferStat(id, this.newStatsData);
          return;
        }
        // nuevos datos
        const data = offerLink.data();
        // agrega la fecha
        data[statType].push({
          date: moment().tz('America/Bogota').format(),
        });
        // suma 1 al counter
        data[`${statType}Counter`] += 1;
        // actualiza la bd
        return this.logOfferStat(id, data);
      })
      .then(() => {
        console.log('stat registered');
      })
      .catch((error) => {
        // registra el error en Sentry
        this.errorMonitoringService.sendError(error, 'logStat');
        return false;
      });
  }

  /** Presenta el modal de ventas*/
  async presentModalSales(
    template: string,
    config: any,
    functionality: string,
  ) {
    //Registra el evento en analitycs
    this.firebaseAnalytics.logEvent('openFindYouProModal', {
      place: functionality,
    });
    // indica que se debe bloquear/desbloquear la navegacion
    this.openModalGuardService.setModalOpen(true);
    // abre el modal
    const confirmModal = await this.modalController.create({
      component: SalesModalComponent,
      cssClass: 'sales-modal',
      componentProps: {
        image: config.image,
        html: this.sanitizer.bypassSecurityTrustHtml(template),
        buttons: config.buttons,
      },
      backdropDismiss: false,
    });
    confirmModal.onDidDismiss().then(() => {
      // indica que se debe bloquear/desbloquear la navegacion
      this.openModalGuardService.setModalOpen(false);
    });
    // presenta el modal
    return await confirmModal.present();
  }

  /** obtiene las ofertas de los ultimos 30 dias */
  getLast30DaysOffers() {
    return this.angularFirestore
      .collection<any>('offers', (ref) =>
        ref
          .where(
            'date',
            '>',
            moment().subtract(30, 'days').format('YYYY-MM-DD'),
          )
          .where('date', '<', moment().format('YYYY-MM-DD'))
          .orderBy('date', 'desc'),
      )
      .get();
  }

  /** obtiene todas las ofertas activas de una ciudad */
  getActiveOffersByCity(city: string) {
    let q = query(
      this.offersRef,
      where('status', '==', 'published'),
      where('cities', 'array-contains', city),
      orderBy('date', 'asc'),
    );
    return getDocs(q);
  }

  /** emite un evento cuando se actualiza una oferta */
  triggerReloadOffers(): void {
    console.log('emite');
    this.reloadOffers.emit();
  }

  /** agrega un registro de busqueda de ofertas */
  addOffersSearch(data: OffersSearchType) {
    return addDoc(this.offersSearchRef, data);
  }

  /** obtiene todos los registros de una palabra clave en la busqueda de ofertas */
  getOffersSearchKeywordByProcessedKeywords(processedKeyword: string) {
    let q = query(
      this.offersSearchKeywordRef,
      where('processedKeyword', '==', processedKeyword),
    );
    return getDocs(q);
  }

  /** agrega un registro de palabra clave en la busqueda de ofertas */
  addOffersSearchKeywords(data: OffersSearchKeywordsType) {
    return addDoc(this.offersSearchKeywordRef, data);
  }

  /** actualiza un registro de palabra clave en la busqueda de ofertas */
  updateOffersSearchKeywords(
    document: DocumentReference<DocumentData>,
    data: OffersSearchKeywordsType,
  ) {
    return updateDoc(document, data);
  }
}
