<ion-content
  class="ion-no-padding"
  scroll-y="false">
  <ion-fab
    vertical="top"
    horizontal="end"
    slot="fixed">
    <ion-fab-button
      color="secondary"
      size="small"
      (click)="closeModal()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-grid class="ion-no-padding">
    <ion-row class="ion-no-padding">
      <!-- imagen pc -->
      <ion-col
        size="12"
        class="pc-modal ion-no-padding">
        <img
          [src]="images.pc"
          alt="" />
      </ion-col>
      <!-- imagen mobile -->
      <ion-col
        size="12"
        class="mobile-modal ion-no-padding">
        <img
          [src]="images.mobile"
          alt="" />
      </ion-col>
      <!-- botones -->
      <ion-col
        size="12"
        *ngIf="buttons && buttons.length > 0"
        class="buttons-wrapper"
        [ngStyle]="{
          bottom: getButtonsPosition('bottom'),
          left: getButtonsPosition('left')
        }">
        <ion-button
          *ngFor="let button of buttons"
          (click)="actionModal(button)"
          [color]="button.color"
          [fill]="button.fill">
          {{ button.title }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
