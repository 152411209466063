import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.page.html',
  styleUrls: ['./select-modal.page.scss'],
})
export class SelectModalPage implements OnInit {
  originalList: any;
  filteredList: any;

  constructor(
    private modalController: ModalController,
    public navParams: NavParams,
  ) {
    // obtiene los parametros de la data
    this.originalList = this.navParams.get('list');
    // valida si debe mostrar la lista completa sin filtro
    if (this.navParams.get('showCompleteList')) {
      this.filteredList = this.originalList;
    } else {
      this.filteredList = [];
    }
  }

  ngOnInit() {}

  filterList(ev: any) {
    if (ev.detail.value === '') {
      // valida si debe mostrar la lista completa sin filtro
      if (this.navParams.get('showCompleteList')) {
        this.filteredList = this.originalList;
      } else {
        this.filteredList = [];
      }
      return;
    }
    this.filteredList = this.originalList.filter((item: any) => {
      if (
        item.name.toUpperCase().match(`.*${ev.detail.value.toUpperCase()}.*`)
      ) {
        return item;
      }
    });
  }

  closeModal(selected = null): void {
    this.modalController.dismiss(selected);
  }
}
