import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { first, map } from 'rxjs/operators';
import { TicketType } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  private ticketsRef = 'tickets';

  constructor(private angularFirestore: AngularFirestore) {}

  /** crea un ticket */
  createTicket(ticket: TicketType): Promise<DocumentReference> {
    return this.angularFirestore.collection<any>(this.ticketsRef).add(ticket);
  }

  /** obtiene un ticket por empresa y link de usuario */
  getTicketByCompanyAndUser(company: string, userLink: string) {
    return this.angularFirestore
      .collection<any>(this.ticketsRef, (ref) =>
        ref
          .where('company.id', '==', company)
          .where('user.link', '==', userLink),
      )
      .get();
  }

  /** obtiene todos los tickets de una empresa */
  getTicketsByCompany(company: string) {
    return this.angularFirestore
      .collection<any>(this.ticketsRef, (ref) =>
        ref.where('company.id', '==', company).orderBy('date', 'desc'),
      )
      .get();
  }
}
