import { ModalController } from '@ionic/angular';
import { ConfirmPage } from './../../pages/confirm/confirm.page';
import { OpenModalGuardService } from './../open-modal-guard/open-modal-guard.service';
import { HelperService } from './../helper/helper.service';
import { UserDataService } from './../user-data/user-data.service';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public showNotifications = false;
  public notificationsState = new BehaviorSubject<any>(false);
  public notificationsStateObservable = this.notificationsState.asObservable();

  constructor(
    private afMessaging: AngularFireMessaging,
    private userDataService: UserDataService,
    private helperService: HelperService,
    private openModalGuardService: OpenModalGuardService,
    private modalController: ModalController,
  ) {}

  // valida si tiene token para messaging
  validateUserToken(user: any): void {
    this.afMessaging.getToken.subscribe(
      (token) => {
        if (token) {
          this.saveTokenToUser(token, user);
        } else {
          this.showNotifications = true;
          this.notificationsState.next(true);
        }
      },
      (error) => {
        console.error('Error obteniendo token', error);
      },
    );
  }

  // save the permission token in firestore
  saveTokenToUser(token: string, user: any): void {
    const currentTokens = user.fcmTokens || [];
    // If token does not exist in firestore, update db
    if (!currentTokens.includes(token)) {
      currentTokens.push(token);
      const updateData = {
        fcmTokens: currentTokens,
      };
      this.userDataService.updateProfile(updateData, user.uid).then(() => {
        // this.helperService.presentMessageAlert('Mensaje', '¡Notificaciones activadas!');
      });
    }
  }

  // abre el modal de activar notificaciones
  async openNotificationsModal(user: any) {
    // indica que se debe bloquear/desbloquear la navegacion
    this.openModalGuardService.setModalOpen(true);
    // crea el modal con el chat
    const confirmModal = await this.modalController.create({
      component: ConfirmPage,
      cssClass: 'confirm-modal',
      componentProps: {
        image: 'assets/imgs/fireworks.png',
        title: '¡Activa los Mensajes!',
        subtitle:
          'Para que estés siempre actualizado Job City quiere activar los mensajes en tu dispositivo',
        buttons: [
          {
            title: 'Ahora no',
            path: '',
            type: '',
          },
          {
            title: 'Activar Mensajes',
            path: '',
            type: 'execute',
            execute: () => {
              this.afMessaging.requestToken.subscribe(
                (token) => {
                  this.saveTokenToUser(token, user);
                  this.notificationsState.next(false);
                },
                (error) => {
                  console.error('Error obteniendo token', error);
                  this.helperService.presentMessageAlert(
                    'Error',
                    'No se pudieron activar los mensajes, inténtalo de nuevo',
                  );
                },
              );
            },
          },
        ],
      },
      backdropDismiss: false,
    });
    confirmModal.onDidDismiss().then(() => {
      // indica que se debe bloquear/desbloquear la navegacion
      this.openModalGuardService.setModalOpen(false);
    });
    // presenta el modal con el mapa
    return await confirmModal.present();
  }
}
