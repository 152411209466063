<ion-header>
  <ion-toolbar color="primary">
    <ion-searchbar
      animated
      placeholder="Escríbe aquí para filtrar..."
      (ionChange)="filterList($event)"
      debounce="400"></ion-searchbar>
    <!-- Botones -->
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <h3
    class="primary-text ion-text-center"
    *ngIf="filteredList.length < 1">
    Comienza a escribir
  </h3>
  <ion-list>
    <ion-item
      button
      class="pointer"
      *ngFor="let option of filteredList"
      (click)="closeModal(option.value)">
      <ion-label text-wrap>{{ option.name }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
