import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { CloseModalButtonType, ModalButtonType } from 'src/app/helperTypes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sales-image-modal',
  templateUrl: './sales-image-modal.component.html',
  styleUrls: ['./sales-image-modal.component.scss'],
})
export class SalesImageModalComponent implements OnInit {
  /** indica la fuente de apertura del modal */
  source = '';
  /** contiene los eventos de analytics */
  analyticsEvents = {
    open: '',
    close: '',
  };
  /** botones de accion del modal */
  buttons: ModalButtonType[] = [];
  /** configuracion del boton flotante de cierre */
  closeButton: CloseModalButtonType = {
    size: '',
    color: '',
    icon: '',
    vertical: '',
    horizontal: '',
  };
  /** contiene las imagenes de fondo */
  images = {
    mobile: '',
    pc: '',
  };
  /** contiene los estilos de posicion de los botones */
  buttonsPosition = {
    pc_bottom: '',
    pc_left: '',
    mobile_bottom: '',
    mobile_left: '',
  };

  constructor(
    private modalController: ModalController,
    private firebaseAnalytics: AngularFireAnalytics,
    public navParams: NavParams,
    private router: Router,
  ) {
    // captura el valor de la fuente
    this.source = this.navParams.get('source') || 'default';
    // captura el valor de analytics
    this.analyticsEvents = this.navParams.get('analyticsEvents') || {
      open: 'openPremiumModal',
      close: 'closePremiumModal',
    };
    // captura el valor para los botones
    this.buttons = this.navParams.get('buttons') || [];
    // captura el valor para el boton de cierre
    this.closeButton = this.navParams.get('closeButton') || {
      size: 'small',
      color: 'secondary',
      icon: 'close',
      vertical: 'top',
      horizontal: 'end',
    };
    // captura el valor para las imagenes
    this.images = this.navParams.get('images') || {
      mobile: 'assets/imgs/sales/premium-mobile.png',
      pc: 'assets/imgs/sales/premium-pc.png',
    };
    // captura el valor de la posicion de los botones
    this.buttonsPosition = this.navParams.get('buttonsPosition');
  }

  ngOnInit() {
    // registra apertura en analytics
    this.firebaseAnalytics.logEvent(this.analyticsEvents.open, {
      place: this.source,
    });
  }

  /** retorna el estilo de los botones */
  getButtonsPosition(position: string) {
    // valida si es pc
    if (document.body.clientWidth > 992) {
      return position === 'bottom'
        ? this.buttonsPosition.pc_bottom
        : this.buttonsPosition.pc_left;
    }
    // envia para mobiles
    return position === 'bottom'
      ? this.buttonsPosition.mobile_bottom
      : this.buttonsPosition.mobile_left;
  }

  /** cierra el modal y ejecuta la accion del boton seleccionado */
  actionModal(button: ModalButtonType): void {
    // registra el evento en analytics
    if (button.analytics && button.analytics.register) {
      this.firebaseAnalytics.logEvent(button.analytics.event, {
        source: this.source,
        ...button.analytics.params,
      });
    }
    this.modalController.dismiss().then(() => {
      // abre enrutamiento local
      if (button.type === 'internal') {
        this.router.navigate([`/${button.path}`]);
      }
      // abre enrutamiento externo
      if (button.type === 'external') {
        window.open(`${button.path}`);
      }
      // ejecuta un evento
      if (button.type === 'execute') {
        button.execute();
      }
    });
  }

  /** contactan a sales en whats app */
  contactSales() {
    this.firebaseAnalytics.logEvent('contactPremiumSales', {
      place: this.source,
    });
    this.modalController.dismiss().then(() => {
      window.open(
        `https://wa.me/${environment.whatsapp}?text=¡Deseo sumarme a Job City Premium!`,
      );
    });
  }

  /** abre la pagina del producto */
  openLanding() {
    this.firebaseAnalytics.logEvent('buyPremiumSales', {
      place: this.source,
    });
    this.modalController.dismiss().then(() => {
      window.open(
        `https://jobcity.com.co/producto/job-city-premium?utm_source=app&utm_medium=app&utm_campaign=modal`,
      );
    });
  }

  /** cierra el modal */
  closeModal() {
    this.firebaseAnalytics.logEvent(this.analyticsEvents.close, {
      place: this.source,
    });
    this.modalController.dismiss();
  }
}
