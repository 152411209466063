import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-sales-modal',
  templateUrl: './sales-modal.component.html',
  styleUrls: ['./sales-modal.component.scss'],
})
export class SalesModalComponent implements OnInit {
  /** contiene la imagen a mostrar */
  image = '';
  /** contiene el html a mostrar */
  html: SafeHtml = '';
  /** contiene los botones de redireccion */
  buttons = '';

  constructor(
    public navParams: NavParams,
    private modalController: ModalController,
    public router: Router,
    private firebaseAnalytics: AngularFireAnalytics,
  ) {
    // obtiene los parametros de la navegación
    this.image = this.navParams.get('image');
    this.html = this.navParams.get('html');
    this.buttons = this.navParams.get('buttons');
  }

  ngOnInit() {}

  /** cierra el modal y ejecuta la accion del boton seleccionado */
  actionModal(button: any): void {
    // registra el evento en analytics
    if (button.analytics && button.analytics.register) {
      this.firebaseAnalytics.logEvent(
        button.analytics.event,
        button.analytics.params,
      );
    }
    this.modalController.dismiss().then(() => {
      // abre enrutamiento local
      if (button.type === 'internal') {
        this.router.navigate([`/${button.path}`]);
      }
      // abre enrutamiento externo
      if (button.type === 'external') {
        window.open(`${button.path}`);
      }
      // abre enrutamiento externo
      if (button.type === 'execute') {
        button.execute();
      }
    });
  }

  /** cierra el modal */
  cancelModal() {
    this.modalController.dismiss();
  }
}
