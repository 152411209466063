import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ErrorMonitoringService } from './../error-monitoring/error-monitoring.service';
import { Injectable } from '@angular/core';
import {
  ToastController,
  AlertController,
  ActionSheetController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { ClipboardService } from 'ngx-clipboard';
import * as moment from 'moment';
import { OpenModalGuardService } from '../open-modal-guard/open-modal-guard.service';
import { SalesImageModalComponent } from 'src/app/components/sales-image-modal/sales-image-modal.component';
import { CloseModalButtonType, ModalButtonType } from 'src/app/helperTypes';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  loading: any;

  constructor(
    private toastController: ToastController,
    private alertController: AlertController,
    private actionSheetController: ActionSheetController,
    private loadingController: LoadingController,
    private errorMonitoringService: ErrorMonitoringService,
    private clipboardService: ClipboardService,
    private firebaseAnalytics: AngularFireAnalytics,
    private angularFireAuth: AngularFireAuth,
    private angularFireFunctions: AngularFireFunctions,
    private openModalGuardService: OpenModalGuardService,
    private modalController: ModalController,
  ) {}

  /** Presenta un toast */
  async presentToast(params: any) {
    const toast = await this.toastController.create(params);
    toast.present();
  }

  /** Presenta un toast con boton de acción y un metodo para ejecutar cuando se cierre */
  async presentToastWithOptions(params: any, method: any) {
    const toast = await this.toastController.create(params);
    toast.present();
    toast.onDidDismiss().then(() => {
      method();
    });
  }

  /** Presenta un alert con un mensaje de alerta */
  async presentMessageAlert(title: string, message: string) {
    const alert = await this.alertController.create({
      header: title,
      cssClass: 'alert-modal',
      subHeader: message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  /** presenta una lista para seleccionar una opcion */
  async presentRadioSelectAlert(
    title: string,
    subtitle: string,
    inputs: Array<any>,
    buttons: Array<any>,
  ) {
    const alert = await this.alertController.create({
      header: title,
      inputs,
      buttons,
    });
    await alert.present();
  }

  /** presenta un alert de confirmacion */
  async presentAlertConfirm(
    header: string,
    message: string,
    buttons: Array<any>,
  ) {
    const alert = await this.alertController.create({
      header,
      subHeader: message,
      buttons,
    });

    await alert.present();
  }

  /** Presenta un action sheet */
  async presentActionSheet(header: string, buttons: Array<any>) {
    const actionSheet = await this.actionSheetController.create({
      header,
      buttons,
    });
    await actionSheet.present();
  }

  /** presenta el componente de carga */
  async presentLoading() {
    this.loading = await this.loadingController.create({
      duration: 20000,
    });
    await this.loading.present();
  }

  /** oculta el componente de carga */
  dismissLoading() {
    if (this.loading) {
      this.loading.dismiss();
    }
  }

  /** calcula los días a una fecha */
  calculateFromDays(date: any) {
    return moment(date).locale('es').fromNow();
  }

  /** calcula los días a una fecha */
  calculateToDays(date: any) {
    return moment(date).locale('es').toNow();
  }

  /** presenta el modal de compartir */
  presentShare(text: string, url: string, app: string) {
    let navigatorWrapper: any;
    navigatorWrapper = window.navigator;
    if (navigatorWrapper && navigatorWrapper.share) {
      navigatorWrapper
        .share({
          text,
          url,
        })
        .then(() => {
          // registra el evento en analytics
          this.firebaseAnalytics.logEvent('share', {
            method: 'navigator',
            app,
          });
        })
        .catch((error) => {
          this.errorMonitoringService.sendError(error, 'share');
        });
    } else {
      this.clipboardService.copyFromContent(`${text}\n ${url}`);
      // avisa al usuario que fue copiado el mensaje
      this.presentMessageAlert(
        'Compartir',
        'El mensaje ha sido copiado al portapapeles',
      );
      // registra el evento en analytics
      this.firebaseAnalytics.logEvent('share', {
        method: 'clipboard',
        app,
      });
    }
  }

  /** recarga la página */
  reloadPage() {
    location.reload();
  }

  /** cierra sesion */
  logout() {
    this.angularFireAuth
      .signOut()
      .then(() => {
        const params = {
          message: 'Sesión cerrada',
          duration: 3000,
          position: 'bottom',
        };
        this.presentToast(params);
        this.reloadPage();
      })
      .catch((error) => {
        this.errorMonitoringService.sendError(error, 'logout');
        this.presentMessageAlert(
          'Mensaje',
          'No pudimos cerrar la sesión, inténtalo de nuevo',
        );
      });
  }

  /** Llama una función en el servidor */
  callFunction(name: string, data: any) {
    const callable = this.angularFireFunctions.httpsCallable(name);
    return callable(data);
  }

  /** convierte un archivo en base 64 */
  convertirBase64(file: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      return reader.result;
    };
    reader.onerror = (error) => {
      this.errorMonitoringService.sendError(error, 'convertirBase64');
      return null;
    };
  }

  /** Remplaza variables dinamicamente en una plantilla de email */
  replaceDinamicEmailVariables(template: string, params: any): string {
    let properties = Object.keys(params);
    for (let property of properties) {
      const replaceRegex = new RegExp(`{{${property}}}`, 'g');
      template = template.replace(replaceRegex, params[property]);
    }
    return template;
  }

  /** abre el modal de sales premium */
  async openPremiumModal(
    source: string,
    images: {
      mobile: string;
      pc: string;
    },
    analyticsEvents: {
      open: string;
      close: string;
    },
    buttons?: ModalButtonType[],
    buttonsPosition?: {
      pc_bottom: string;
      pc_left: string;
      mobile_bottom: string;
      mobile_left: string;
    },
    closeButton?: CloseModalButtonType,
  ) {
    // indica que se debe bloquear/desbloquear la navegacion
    this.openModalGuardService.setModalOpen(true);
    // abre el modal
    const confirmModal = await this.modalController.create({
      component: SalesImageModalComponent,
      cssClass: 'sales-image-modal',
      componentProps: {
        source,
        images,
        analyticsEvents,
        buttons,
        closeButton,
        buttonsPosition,
      },
      backdropDismiss: false,
    });
    // cuando cierran el modal
    confirmModal.onDidDismiss().then(() => {
      // indica que se debe bloquear/desbloquear la navegacion
      this.openModalGuardService.setModalOpen(false);
    });
    // presenta el modal
    return await confirmModal.present();
  }

  /** Levenshtein distance algorithm (also known as edit distance)
   * to measure the difference between two strings */
  levenshteinDistance(str1: string, str2: string) {
    const m = str1.length;
    const n = str2.length;
    const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

    for (let i = 0; i <= m; i++) {
      for (let j = 0; j <= n; j++) {
        if (i === 0) {
          dp[i][j] = j;
        } else if (j === 0) {
          dp[i][j] = i;
        } else {
          const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
          dp[i][j] = Math.min(
            dp[i - 1][j - 1] + cost,
            dp[i - 1][j] + 1,
            dp[i][j - 1] + 1,
          );
        }
      }
    }

    return dp[m][n];
  }

  /** calcula el porcentaje de similitud entre dos strings
   * basado en el algoritmo de Levenshtein */
  similarityPercentage(str1: string, str2: string) {
    const distance = this.levenshteinDistance(str1, str2);
    const maxLength = Math.max(str1.length, str2.length);
    const similarity = 1 - distance / maxLength;
    return similarity * 100;
  }
}
