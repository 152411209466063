import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  /** nombre de la coleccion de notificaciones */
  subscriptionCollectionName = 'subscriptions';
  /** referencia a la coleccion de notificaciones */
  private subscriptionsRef = collection(
    this.firestore,
    this.subscriptionCollectionName,
  );

  constructor(
    private angularFirestore: AngularFirestore,
    private firestore: Firestore,
  ) {}

  /**
   * @description Crea el documento subscription en Firestore
   *
   * @author Nico Bernal <nicolasbernal17@gmail.com>
   * @date 2020-26-12
   * @returns {Promise<any>}
   * @memberof SubscriptionsService
   */
  public createSubscription(subscription: any): Promise<any> {
    return this.angularFirestore
      .collection<any>(this.subscriptionCollectionName)
      .add(subscription);
  }

  public updateSubscription(subscription: any, subscriptionId: string) {
    return this.angularFirestore
      .collection<any>(this.subscriptionCollectionName)
      .doc(subscriptionId)
      .update(subscription);
  }

  /**
   * @description Obtienes las suscripciones del usuario
   *
   * @author Nico Bernal <nicolasbernal17@gmail.com>
   * @date 2020-26-12
   * @returns {Observable<any[]>}
   * @memberof SubscriptionsService
   */
  getSubscriptions(userId: string): Observable<any[]> {
    return this.angularFirestore
      .collection<any>(this.subscriptionCollectionName, (ref) =>
        ref.where('user.id', '==', userId),
      )
      .snapshotChanges()
      .pipe(
        map((subscriptions) => {
          return subscriptions.map((offer) => {
            const data = offer.payload.doc.data();
            const id = offer.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }

  /** Obtiene las suscripciones del usuario */
  getSubscriptionsByUser(userId: string) {
    const q = query(this.subscriptionsRef, where('user.id', '==', userId));
    return getDocs(q);
  }
}
