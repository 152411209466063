<ion-content class="ion-padding">
  <ion-grid class="confirm-wrap">
    <ion-row
      class="confirm-row ion-align-items-center ion-justify-content-center">
      <ion-col
        size="12"
        class="primary-text ion-text-center">
        <ion-img
          class="picture"
          [src]="image"></ion-img>
        <h1 class="title">{{ title }}</h1>
        <h3 class="subtitle tw-my-4">{{ subtitle }}</h3>
        <ion-button
          *ngFor="let button of buttons"
          (click)="closeModal(button)"
          class="button"
          >{{ button.title }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
