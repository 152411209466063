import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class MembersDataService {
  membersRef = 'membersCountV2';
  members: any;

  constructor(private angularFirestore: AngularFirestore) {}

  // consulta todas las ofertas publicadas de un usuario
  getMembersCount() {
    return this.angularFirestore
      .collection<any>(this.membersRef, (ref) =>
        ref.orderBy('date', 'desc').limit(1),
      )
      .get();
  }

  // devuelve la data almacenada
  getMembersData() {
    return new Promise(async (resolve, reject) => {
      if (!this.members) {
        await this.getMembersCount()
          .toPromise()
          .then((querySnapshot) => {
            // si existen usuarios
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                this.members = doc.data();
              });
              resolve(this.members);
            } else {
              resolve(0);
            }
          })
          .catch((error) => {
            console.error('No se pudo obtener los datos de miembros', error);
            reject();
          });
      } else {
        resolve(this.members);
      }
    });
  }
}
