import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { PostulationType } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class PostulationsService {
  /** nombre coleccion postulaciones */
  private postulationsCollectionName = 'postulations';

  constructor(private angularFirestore: AngularFirestore) {}

  /** agrega una postulación */
  addPostulation(postulation: PostulationType): Promise<any> {
    return this.angularFirestore
      .collection<any>(this.postulationsCollectionName)
      .add(postulation);
  }

  /** consulta todas las postulaciones de una oferta */
  getOfferPostulations(offer: string): any {
    return this.angularFirestore
      .collection<any>(this.postulationsCollectionName, (ref) =>
        ref.where('offer.id', '==', offer).orderBy('date', 'asc'),
      )
      .snapshotChanges()
      .pipe(
        map((postulations) => {
          return postulations.map((postulation) => {
            const docRef = postulation.payload.doc;
            const data = postulation.payload.doc.data();
            const id = postulation.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
      );
  }

  /** obtiene los datos de una postulacion por su id */
  getPostulationById(id: string) {
    return this.angularFirestore
      .collection<any>(this.postulationsCollectionName)
      .doc(id)
      .get();
  }

  /** actualiza una postulacion */
  updatePostulation(
    id: string,
    postulationData: PostulationType,
  ): Promise<void> {
    return this.angularFirestore
      .collection<any>(`postulations`)
      .doc(`${id}`)
      .update(postulationData);
  }

  /** obtiene todas las postulaciones de un usuario */
  getUserPostulations(userId: string) {
    return this.angularFirestore
      .collection<any>(this.postulationsCollectionName, (ref) =>
        ref.where('user.id', '==', userId).orderBy('date', 'desc'),
      )
      .get();
  }
}
