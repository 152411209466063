import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { PerformanceCompetenceType, PerformanceUserType } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class PerformanceService {
  /** nombre coleccion usuarios performance */
  performanceUsersRef = 'performanceUsers';
  /** nombre coleccion competencias performance */
  performanceCompetencesRef = 'performanceCompetences';

  constructor(private angularFirestore: AngularFirestore) {}

  /** agrega un usuario de performance */
  addPerformanceUser(user: PerformanceUserType): Promise<DocumentReference> {
    return this.angularFirestore
      .collection<any>(this.performanceUsersRef)
      .add(user);
  }

  /** obtiene todos los usuarios performance */
  getPerformanceUsers() {
    return this.angularFirestore
      .collection<any>(this.performanceUsersRef)
      .get();
  }

  /** agrega una competencia de performance */
  addPerformanceCompetence(
    competence: PerformanceCompetenceType,
  ): Promise<DocumentReference> {
    return this.angularFirestore
      .collection<any>(this.performanceCompetencesRef)
      .add(competence);
  }

  /** obtiene las competencias performance */
  getPerformanceCompetences() {
    return this.angularFirestore
      .collection<any>(this.performanceCompetencesRef)
      .get();
  }

  /** actualiza un usuario performance */
  updatePerformanceCompetence(
    competence: PerformanceCompetenceType,
    id: string,
  ) {
    return this.angularFirestore
      .collection<any>(this.performanceCompetencesRef)
      .doc(`${id}`)
      .update(competence);
  }

  /** obtiene un usuario performance por su email */
  getPerformanceUserByEmail(email: string) {
    return this.angularFirestore
      .collection<any>(this.performanceUsersRef, (ref) =>
        ref.where('email', '==', email),
      )
      .get();
  }

  /** obtiene un usuario performance por su id */
  getPerformanceUserById(id: string) {
    return this.angularFirestore
      .collection<any>(this.performanceUsersRef)
      .doc(id)
      .snapshotChanges();
  }

  /** actualiza un usuario performance */
  updateUser(user: PerformanceUserType, id: string) {
    return this.angularFirestore
      .collection<any>(this.performanceUsersRef)
      .doc(`${id}`)
      .update(user);
  }
}
