<ion-header>
  <ion-toolbar [color]="iframe.header.color">
    <!-- Botones -->
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon [name]="iframe.header.closeIcon"></ion-icon>
      </ion-button>
    </ion-buttons>
    <!-- Titulo -->
    <ion-title class="ion-text-center">
      <img
        class="header-icon"
        [src]="iframe.header.icon"
        alt="" />
      <img
        class="header-icon2"
        [src]="iframe.header.icon2"
        alt="" />
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <iframe
    id="iframeWrapper"
    [title]="iframe.title"
    class="frame-container">
  </iframe>
</ion-content>
