import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GoogleCloudVisionServiceService {
  constructor(public http: HttpClient) {}

  getImageText(base64Image: any) {
    const body = {
      requests: [
        {
          features: [
            {
              type: 'DOCUMENT_TEXT_DETECTION',
            },
          ],
          image: {
            content: base64Image,
          },
        },
      ],
    };
    return this.http.post(
      `https://vision.googleapis.com/v1/images:annotate?key=${environment.googleCloudVisionAPIKey}`,
      body,
    );
  }
}
