import { CompanyGuardService } from './../../services/company-guard/company-guard.service';
import { FunnelGuardService } from './../../services/funnel-guard/funnel-guard.service';
import { OpenModalGuardService } from './../../services/open-modal-guard/open-modal-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'empresas',
    loadChildren: () =>
      import('./companies/companies.module').then((m) => m.CompaniesPageModule),
    canActivate: [CompanyGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'empresas/:companyId',
    loadChildren: () =>
      import('./manage-company/manage-company.module').then(
        (m) => m.ManageCompanyPageModule,
      ),
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'users/:companyId',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersPageModule),
    canActivate: [CompanyGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'publicar-oferta',
    loadChildren: () =>
      import('./funnel/funnel.module').then((m) => m.FunnelPageModule),
    canActivate: [CompanyGuardService],
    canDeactivate: [OpenModalGuardService, FunnelGuardService],
  },
  {
    path: 'offers',
    loadChildren: () =>
      import('./offers/offers.module').then((m) => m.OffersPageModule),
    canActivate: [CompanyGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'detalle-oferta/:offer',
    loadChildren: () =>
      import('./offers/offer-detail/offer-detail.module').then(
        (m) => m.OfferDetailPageModule,
      ),
    canActivate: [CompanyGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'postulacion/:postulation',
    loadChildren: () =>
      import('./postulation/postulation.module').then(
        (m) => m.PostulationPageModule,
      ),
    canActivate: [CompanyGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'minibios/:companyId',
    loadChildren: () =>
      import('./minibios/minibios.module').then((m) => m.MinibiosPageModule),
    canActivate: [CompanyGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PubliofertasRoutingModule {}
