<ion-content>
  <!-- PDF -->
  <div id="to-print-pdf">
    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>
    <div class="content-body">
      <h2 class="ion-text-center">
        Performance persona: {{ user.name | uppercase }} {{ user.surname |
        uppercase }}
      </h2>
      <h2 class="ion-text-center subtitle">
        ¡Bienvenido al reporte performance!
      </h2>
      <h2><b>Primera sección</b></h2>
      <h3 class="ion-text-justify gray-color">
        Encontrará una gráfica de un cerebro que muestra las características
        asociadas a cada estilo de pensamiento. Por favor lea con detenimiento
        las características y atributos asociados a cada estilo, de esta manera
        podrá avanzar más fácil en este informe.
      </h3>
      <h2><b>Estilo de pensamiento resultados</b></h2>
      <h1 class="ion-text-center">Su fortaleza: {{ user.result.fortaleza }}</h1>
      <div class="estilo-print-card">
        <!-- Chips -->
        <div class="chips-wrapper">
          <div class="estilo-chip analitico">
            <label><b>Analítico {{ user.result.ei }}</b></label>
            <div>
              Lógico-Analitico-Confrontador-Toma de desisiones-Orientado a
              resultados.
            </div>
          </div>
          <div class="estilo-chip eficiente">
            <label><b>Eficiente {{ user.result.ai }}</b></label>
            <div>Orden-Procedimental-Productivo-Cauteloso-Eficiente</div>
          </div>
          <div class="estilo-chip empatico">
            <label><b>Empático {{ user.result.ad }}</b></label>
            <div>
              Armonizador-Conciliador-Sensitivo-Empático-Sentido de pertencia
            </div>
          </div>
          <div class="estilo-chip creativo">
            <label><b>Creativo {{ user.result.ed }}</b></label>
            <div>Creativo-Visionario-Imnovador-Metafórico-Toma riesgos</div>
          </div>
          <div class="margin-grand-torta">
            <canvas #barChart></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="html2pdf__page-break"></div>
    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>
    <div class="content-body">
      <h2 class="ion-text-center">
        Performance persona: {{ user.name | uppercase }} {{ user.surname |
        uppercase }}
      </h2>
      <h2><b>Estilos de pensamiento: Resultados</b></h2>
      <h4 class="ion-text-justify gray-color">
        {{ user.name }} ha logrado potenciar su estilo de pensamiento natural,
        seguramente esto le permite todos los días ser más feliz y saludable.
      </h4>
      <h4 class="ion-text-justify gray-color">
        Las personas que logran usar de modo cotidiano y desarrollar
        competencias en su modo preferente o natural:
      </h4>
      <h4 class="ion-text-justify gray-color">
        • Aceptan y dirigen sus debilidades naturales en forma eficiente.
      </h4>
      <h4 class="ion-text-justify gray-color">
        • Logran aplicar de manera fácil y cómoda su habilidades y talentos
        naturales.
      </h4>
      <div class="margin-grand-torta">
        <canvas #horizontalBarChart></canvas>
      </div>
      <h2 class="ion-text-justify"><b>Recuerde</b></h2>
      <h4 class="ion-text-justify gray-color">
        Es importante recordar que ningún estilo de pensamiento es mejor o peor
        que otro, lo más importante es poder entender en dónde fluyo con mayor
        naturalidad porque implica realizar tareas que se le facilitan, le
        gustan y las disfruta.
      </h4>
    </div>
    <div class="html2pdf__page-break"></div>
    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>
    <div class="content-body">
      <h2 class="ion-text-center">
        Performance persona: {{ user.name | uppercase }} {{ user.surname |
        uppercase }}
      </h2>
      <h2 class="ion-text-center"><b>¿Cómo entender los resultados?</b></h2>
      <h4 class="ion-text-center ion-text-justify gray-color">
        El gráfico de barras permite identificar los estilos en los cuales se
        tiene desarrollo de competencias. Los puntajes encima de 80 evidencian
        que las competencias asociadas a este estilo de pensamiento se
        encuentran desarrolladas. La lectura de los puntajes obtenidos en este
        gráfico deben hacerse así:
      </h4>
      <div class="puntajes">
        <h2 class="ion-text-center">Puntajes entre:</h2>
        <h2 class="ion-text-center">
          <b>0-39: </b>Las competencias asociadas a este estilo de pensamiento
          se evitan.
        </h2>
        <h2 class="ion-text-center">
          <b>40-79: </b>Desarrollo Moderado de competencias asociado a este
          estilo de pensamiento.
        </h2>
        <h2 class="ion-text-center">
          <b>80-99: </b>Desarrollo Alto de competencias asociadas a este estilo
          de pensamiento.
        </h2>
        <h2 class="ion-text-center">
          <b>>39: </b>Desarrollo muy alto ó comprometido de las competencias
          asociadas a este estilo de pensamiento.
        </h2>
      </div>
      <h4 class="ion-text-center ion-text-justify gray-color">
        Con este entendimiento podrá identificar si los puntajes evidencian no
        sólo desarrollo presente sino también competencias que se han venido
        desarrollando, dado su rol actual o tareas exigidas en el día a día. Con
        esta información podrá ver si la persona está desarrollando competencias
        moderadas ó altas en un estilo de pensamiento que puede desarrollar dada
        su fortaleza natural o por el contrario está desarrollando competencias
        en un estilo que puede ser su lado más débil por lo que puede estar
        exigiendo mayor esfuerzo y consumo de energía.
      </h4>
    </div>
    <div class="html2pdf__page-break"></div>
    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>
    <div class="content-body">
      <h2 class="ion-text-center">
        Performance persona: {{ user.name | uppercase }} {{ user.surname |
        uppercase }}
      </h2>
      <h4 class="ion-text-center ion-text-justify gray-color">
        Los gráficos a continuación le permitirán ver cuál es su estilo más
        usado en su Tiempo libre, Laboral, Autopercepción, Joven y Adulto.
      </h4>
      <h4 class="ion-text-center ion-text-justify gray-color">
        Los gráficos a continuación le permitirán ver cuál es su estilo más
        usado en su Tiempo libre, Laboral, Siempre los puntajes más altos están
        asociados a su modo de pensamiento preferido o usado en esas
        situaciones. Entender cual es su modo preferente en cada situación, le
        permitirá entender qué habilidades y competencias se ponen de evidencia
        cuando realiza “X” tarea o se encuentra en un ambiente determinado.
      </h4>
      <h4 class="ion-text-center ion-text-justify gray-color">
        Recuerde que el modo que mayor puntuación tiene no está relacionado con
        su modo natural, es posible que el entorno actual, lo esté llevando a
        desarrollar competencias en un modo o estilo de pensamiento que no puede
        ser su natural, esto como respuesta o adaptación a las exigencia a su
        entorno, puede ser una forma de adaptar su estilo natural desarrollando
        competencias esperadas y que son reconocidas por el entorno
      </h4>
      <div class="varius-grafic">
        <div
          size="12"
          class="ion-text-center size-grafic">
          <h5><b>Sus resultados joven:</b></h5>
          <div class="torta"><canvas #jovenChart></canvas></div>
        </div>

        <div
          size="12"
          class="ion-text-center size-grafic">
          <h5><b>Sus resultados adulto:</b></h5>
          <div class="torta"><canvas #adultoChart></canvas></div>
        </div>
      </div>
      <div class="varius-grafic">
        <div
          size="12"
          class="ion-text-center size-grafic">
          <h5><b>Sus resultados tiempo Libre:</b></h5>
          <div class="torta"><canvas #libreChart></canvas></div>
        </div>

        <div
          size="12"
          class="ion-text-center size-grafic">
          <h5><b>Sus resultados laboral:</b></h5>
          <div class="torta"><canvas #laboralChart></canvas></div>
        </div>
      </div>
      <div
        size="12"
        class="ion-text-center size-grafic">
        <h5><b>Sus resultados autopercepción:</b></h5>
        <div class="torta"><canvas #autoChart></canvas></div>
      </div>
    </div>
    <div class="html2pdf__page-break"></div>
    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>
    <div class="content-body">
      <h2 class="ion-text-center">
        Performance persona: {{ user.name | uppercase }} {{ user.surname |
        uppercase }}
      </h2>
      <h2><b>Segunda sección</b></h2>
      <h4 class="ion-text-center ion-text-justify gray-color">
        Se define cuál es su NEUROFORTALEZA, es decir en qué estilo de
        pensamiento están las cosas que se le facilitan. Hay una descripción de
        las características asociadas a este estilo de pensamiento. También se
        describen las características y tareas asociadas a su NEURODEBILIDAD, es
        decir el estilo de pensamiento donde están las cosas que se le
        dificultan.
      </h4>
      <h4 class="ion-text-center ion-text-justify">
        <b>Neurofortaleza: {{ user.result.fortaleza}} </b>
      </h4>
      <div
        class="ion-text-justify"
        innerHTML="{{ user.result.desc_fortaleza}}"></div>
      <h4 class="ion-text-center ion-text-justify">
        <b>Neurodebilidad: {{ user.result.opuesto}}</b>
      </h4>
      <div
        class="ion-text-justify"
        innerHtml="{{ user.result.desc_opuesto}}"></div>
      <h4 class="ion-text-center ion-text-justify">
        <b>Perfíl de competencias:</b>
      </h4>
      <div
        class="ion-text-justify"
        innerHtml="{{user.result.descripcion}}"></div>
    </div>
    <div class="html2pdf__page-break"></div>
    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>
    <div class="content-body">
      <h2 class="ion-text-center">
        Performance persona: {{ user.name | uppercase }} {{ user.surname |
        uppercase }}
      </h2>
      <h2><b>Tercera sección</b></h2>
      <h4 class="ion-text-center ion-text-justify gray-color">
        Aquí encuentra información adicional acerca de 5 aspectos relevantes
        para completar el perfil de resultados : Personalidad, Comunicación,
        Toma de decisiones, Estilo de liderazgo y Tareas que lo motivan.
      </h4>
      <!--personalidad -->
      <div class="global-card">
        <div>
          <img
            src="assets/imgs/conociendome/personalidad.png"
            alt="" />
        </div>
        <div class="conociendome-right-card color-conociendome-pdf">
          <h3 class="margin-title-pdf"><b>Personalidad</b></h3>
          <div
            class="description-card-perfil ion-text-center ion-text-justify"
            [innerHTML]="user.result.personalidad"></div>
        </div>
      </div>
    </div>
    <div class="html2pdf__page-break"></div>
    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>
    <div class="content-body">
      <div class="global-card">
        <div>
          <img
            src="assets/imgs/conociendome/comunicacion.png"
            alt="" />
        </div>
        <div
          class="conociendome-right-card ion-text-justify color-conociendome-pdf">
          <h3 class="margin-title-pdf"><b>Comunicación</b></h3>
          <div
            class="description-card-perfil ion-text-center ion-text-justify"
            [innerHTML]="user.result.comunicacion"></div>
        </div>
      </div>
    </div>
    <div class="html2pdf__page-break"></div>
    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>
    <div class="content-body">
      <!--liderazgo -->
      <div class="global-card">
        <div>
          <img
            src="assets/imgs/conociendome/liderazgo.png"
            alt=""
            class="movil-only" />
        </div>
        <div
          class="conociendome-right-card ion-text-justify color-conociendome-pdf">
          <h3 class="margin-title-pdf"><b>Liderazgo</b></h3>
          <div
            class="description-card-perfil ion-text-center ion-text-justify"
            [innerHTML]="user.result.estilo_liderazgo"></div>
        </div>
      </div>
    </div>
    <div class="html2pdf__page-break"></div>
    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>
    <div class="content-body">
      <!--toma de desiciones -->
      <div class="global-card">
        <div>
          <img
            src="assets/imgs/conociendome/IMG_1603.PNG"
            alt=""
            class="movil-only" />
        </div>
        <div
          class="conociendome-right-card ion-text-justify color-conociendome-pdf">
          <h3 class="margin-title-pdf"><b>Toma de desiciones</b></h3>
          <div
            class="description-card-perfil ion-text-center ion-text-justify"
            [innerHTML]="user.result.toma_decisiones"></div>
        </div>
      </div>
    </div>
    <div class="html2pdf__page-break"></div>

    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>

    <!--tareas que lo motivan -->
    <div class="content-body">
      <div class="global-card">
        <div>
          <img
            src="assets/imgs/conociendome/tareas.png"
            alt=""
            class="movil-only" />
        </div>
        <div
          class="conociendome-right-card ion-text-justify color-conociendome-pdf">
          <h3 class="margin-title-pdf"><b>Tareas que lo motivan</b></h3>
          <div
            class="description-card-perfil ion-text-center ion-text-justify"
            [innerHTML]="user.result.tareas_motivan"></div>
        </div>
      </div>
    </div>
    <div class="html2pdf__page-break"></div>
    <header class="native-header">
      <img src="assets/imgs/logo-pdf.png" />
    </header>
    <div
      class="content-body"
      style="margin-bottom: 30px">
      <h2 class="ion-text-center">
        Performance persona: {{ user.name | uppercase }} {{ user.surname |
        uppercase }}
      </h2>
      <h2><b>Cuarta sección</b></h2>
      <h3 class="ion-text-justify gray-color">
        Aquí encuentra los planes de acción asociados a las competencias
        seleccionadas por usted previamente y que requieren ser desarrolladas.
        Los planes de acción sugeridos, son tomados de información de trabajo
        con personas y equipos. Permiten dar una guía de acción para emprender
        nuevos aprendizajes.
      </h3>
      <h2><b>Planes de acción</b></h2>
      <div
        *ngFor="let plan of user.pdi; let i = index"
        class="style-plan-card">
        <div><b>{{plan.title}}</b></div>
        <div>
          <div class="plan-style">
            <div [innerHTML]="plan.plan"></div>
          </div>
        </div>
        <div
          class="html2pdf__page-break"
          *ngIf="(i === 2) || (i === 5)"></div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-text-center">
    <ion-button
      fill="outline"
      (click)="closeModal()"
      *ngIf="!loading">
      Cancelar
    </ion-button>
    <ion-button
      (click)="print()"
      *ngIf="!loading">
      Descargar PDF
    </ion-button>
    <ion-button
      fill="outline"
      *ngIf="loading">
      Descargando
      <ion-spinner
        color="primary"
        name="dots"></ion-spinner>
    </ion-button>
  </ion-toolbar>
</ion-footer>
