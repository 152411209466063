import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OpenModalGuardService implements CanDeactivate<any> {
  modalOpen = false;

  constructor() {}

  canDeactivate(): boolean {
    return !this.modalOpen;
  }

  setModalOpen(open: boolean) {
    this.modalOpen = open;
  }
}
