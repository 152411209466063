import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SalesModalComponent } from './sales-modal.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [SalesModalComponent],
  imports: [CommonModule, FormsModule, IonicModule],
})
export class SalesModalModule {}
