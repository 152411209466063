import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import firebase from 'firebase/compat/app';
import AuthProvider = firebase.auth.AuthProvider;
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { first, map, take } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ErrorMonitoringService } from '../error-monitoring/error-monitoring.service';
import { HelperService } from '../helper/helper.service';
import * as moment from 'moment-timezone';
import { Storage, ref, uploadBytesResumable } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  /** datos del usuario */
  private user: any;
  /** subject del estado del usuario */
  private userState = new BehaviorSubject<any>(null);
  /** observable del subject del estado del usuario */
  userStateObservable = this.userState.asObservable();
  /** token de auth */
  authToken = null;
  /** contiene la url denegada por auth */
  deniedUrl = [];
  /** coleccion de stats para minibio */
  private statsRef = 'minibioStats';
  /** contiene el objeto para inicializar las estidisticas en una minibio */
  private newStatsData = {
    pageViewCounter: 1,
    pageView: [
      {
        date: moment().tz('America/Bogota').format(),
      },
    ],
    contactPhoneCounter: 0,
    contactPhone: [],
    contactEmailCounter: 0,
    contactEmail: [],
    viewCvCounter: 0,
    viewCv: [],
    viewLinkedinCounter: 0,
    viewLinkedin: [],
    shareCounter: 0,
    share: [],
  };

  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private storage: AngularFireStorage,
    private errorMonitoringService: ErrorMonitoringService,
    private helperService: HelperService,
    private newStorage: Storage,
  ) {
    // Se encarga de emitir el estado del user a los componentes que se subscriban al observable userStateObservable
    this.angularFireAuth.authState.subscribe(
      (user) => {
        if (user) {
          user
            .getIdToken(true)
            .then((token) => {
              this.authToken = token;
            })
            .catch((error) => {
              this.errorMonitoringService.sendError(error, 'getIdToken');
            });
          this.user = user;
          this.getProfile(user.uid).subscribe(
            (userData) => {
              this.userState.next({ ...userData, user });
            },
            (error) => {
              this.errorMonitoringService.sendError(error, 'getProfile');
            },
          );
        } else {
          this.userState.next(null);
        }
      },
      (error) => {
        this.errorMonitoringService.sendError(error, 'getUser');
        this.helperService.presentMessageAlert(
          'Mensaje',
          'No pudimos obtener tu usuario, inténtalo de nuevo',
        );
      },
    );
  }

  /** devuelve el token de auth */
  getAuthToken(): any {
    return this.authToken;
  }

  /** obtiene el token de auth */
  obtainAuthToken(): Promise<any> {
    return new Promise((resolve) => {
      this.user
        .getIdToken(true)
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          this.errorMonitoringService.sendError(error, 'obtainAuthToken');
        });
    });
  }

  /** login mediante email */
  public emailLogin(credentials) {
    return this.angularFireAuth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password,
    );
  }

  /** login mediante email */
  public emailRegister(credentials) {
    return this.angularFireAuth.createUserWithEmailAndPassword(
      credentials.email,
      credentials.password,
    );
  }

  /** login con google */
  public googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.authProviderLogin(provider);
  }

  /** login with facebook */
  public facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.authProviderLogin(provider);
  }

  /** login mediante popup */
  private authProviderLogin(provider: AuthProvider) {
    return this.angularFireAuth.signInWithPopup(provider);
  }

  /** Crea el objeto userProfile en firestore */
  public createProfile(credentials: any, userUID: string): Promise<any> {
    const profile = {
      name: credentials.name,
      surname: credentials.surname,
      email: credentials.email,
      mobile: credentials.mobile,
      country: 'COL',
      registerDate: moment().tz('America/Bogota').format(),
      profileImage: credentials.profileImage || null,
      minibio: null,
    };
    return this.angularFirestore
      .collection<any>(`userProfile`)
      .doc(userUID)
      .set(profile);
  }

  /** actualiza un userProfile */
  public updateProfile(userData: any, userId: string) {
    return this.angularFirestore
      .collection<any>(`userProfile`)
      .doc(`${userId}`)
      .update(userData);
  }

  /** desvincula el acceso con celular */
  public unlinkPhoneAccount() {
    return this.user.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID);
  }

  /** obtiene el perfil del ususuario por id en suscripcion */
  public getProfile(id: string) {
    return this.angularFirestore
      .doc<any>(`userProfile/${id}`)
      .snapshotChanges()
      .pipe(
        map((userData) => {
          const data = userData.payload.data();
          const uid = userData.payload.id;
          if (data) {
            return { uid, ...data };
          }
        }),
      );
  }

  /** obtiene el perfil del ususuario por id en promesa */
  public getUserById(id: string) {
    return this.angularFirestore
      .doc<any>(`userProfile/${id}`)
      .snapshotChanges()
      .pipe(
        map((userData) => {
          const data = userData.payload.data();
          const uid = userData.payload.id;
          if (data) {
            return { uid, ...data };
          }
        }),
        first(),
      )
      .toPromise();
  }

  /** obtiene el perfil de un user por uid en promesa */
  async getProfileByUid(uid: string) {
    return this.angularFirestore
      .collection<any>('userProfile')
      .doc(uid)
      .get()
      .toPromise()
      .then((user) => {
        return user.data();
      });
  }

  /** obtiene el perfil de un usuario por id */
  getUserByUid(uid: string) {
    return this.angularFirestore.collection<any>('userProfile').doc(uid).get();
  }

  /** envia un email para reestablecer contraseña */
  recoveryPassword(email: string) {
    return this.angularFireAuth.sendPasswordResetEmail(email, {
      url: environment.baseUrl,
    });
  }

  /** valida si un usuario existe */
  async userExists(email: string) {
    const userRef = await this.angularFirestore
      .collection('userProfile', (ref) => ref.where('email', '==', email))
      .get()
      .toPromise();
    // si no existe el usuario en la app
    if (userRef.empty) {
      return {
        status: false,
        reason: 'not_found',
      };
    }
    // si encuentra resultados
    const userData: {
      user: any;
      uid: string;
    } = {
      user: userRef.docs[0].data(),
      uid: userRef.docs[0].id,
    };
    return {
      status: true,
      data: userData,
    };
  }

  /** almacena una imagen en storage */
  uploadImage(client: string, image: any) {
    return new Promise((resolve, reject) => {
      let filePath;
      // Ruta en la que se almacena
      filePath = `images/${client}/${image.name}`;
      // Almacena la imagen
      this.storage
        .upload(filePath, image)
        .then((snapshot) => {
          // Obtiene la url de descarga
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
          });
        })
        .catch((error) => {
          this.errorMonitoringService.sendError(error, 'uploadUserImage');
          reject();
        });
    });
  }

  /** almacena un archivo en storage */
  uploadFile(client: string, image: any) {
    // Ruta en la que se almacena
    const filePath = `images/${client}/${image.name}`;
    // Almacena la imagen
    return this.storage.upload(filePath, image);
  }

  /** almacena un documento en storage */
  uploadBlob(client: string, document: any) {
    // Ruta en la que se almacena
    const filePath = `images/${client}/${document.name}`;
    // crea la referencia
    const ref = this.storage.ref(filePath);
    // Almacena el documento
    return ref.put(document);
  }

  /** almacena un documento en storage con el nuevo sdk */
  uploadBlobNewSDK(client: string, document: File) {
    // Ruta en la que se almacena
    const filePath = `images/${client}/${document.name}`;
    // crea la referencia
    const storageRef = ref(this.newStorage, filePath);
    // Almacena el documento
    return uploadBytesResumable(storageRef, document);
  }

  /** retorna el usuario */
  getUser(): any {
    return this.user;
  }

  /** retorna el uid del usuario */
  getUserId() {
    if (this.user) {
      return this.user.uid;
    }
    return null;
  }

  /** retorna el observable de auth */
  get currentUserObservable(): any {
    return this.angularFireAuth.authState;
  }

  /** indica si hay o no usuario */
  getUserPromise() {
    return this.angularFireAuth.authState.pipe(
      take(1),
      map((user) => {
        return !!user; // lo mapea a booleano
      }),
    );
  }

  /** guarda una url de redirección en caso que authguard no le haya permitido ingresar
   * para redirigir nuevamente al user a lo que buscaba cuando haga login */
  saveUrlDeniedLogin(url: Array<string>): void {
    this.deniedUrl = url;
  }

  /** retorna la url denegada */
  get getDeniedUrl(): any {
    return this.deniedUrl;
  }

  /** borra la url denegada */
  clearDeniedUrl(): void {
    this.deniedUrl = [];
  }

  /** valida si existe ese usuario en la mini bio */
  validateUsername(username: string) {
    return this.angularFirestore
      .collection<any>('userProfile', (ref) =>
        ref.where('minibio.link', '==', username),
      )
      .get();
  }

  /** obtiene las urls de los videos */
  getVideos() {
    return this.angularFirestore
      .collection<any>('videos')
      .snapshotChanges()
      .pipe(
        map((videos) => {
          return videos.map((video) => {
            const data = video.payload.doc.data();
            const id = video.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }

  /** consulta los ultimos 5000 usuarios sin minibio */
  getNullMinibios() {
    return this.angularFirestore
      .collection<any>('userProfile', (ref) =>
        ref
          .where('minibio', '==', null)
          .limit(5000)
          .orderBy('registerDate', 'desc'),
      )
      .snapshotChanges()
      .pipe(
        map((beneficiaries) => {
          return beneficiaries.map((beneficiary) => {
            const data = beneficiary.payload.doc.data();
            const id = beneficiary.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }

  /** obtiene todos los usuarios de la base de datos */
  getAllUsers() {
    return this.angularFirestore.collection<any>('userProfile').get();
  }

  /** obtiene todas las suscripciones a find me de la bd */
  getSubscriptions() {
    return this.angularFirestore
      .collection<any>('subscriptions')
      .snapshotChanges()
      .pipe(
        map((suscriptions) => {
          return suscriptions.map((suscription) => {
            const data = suscription.payload.doc.data();
            const id = suscription.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }

  /** obtiene todas las ofertas */
  getOffers() {
    return this.angularFirestore
      .collection<any>('offers', (ref) =>
        ref.where('date', '>', '2022-03-05').orderBy('date', 'desc'),
      )
      .snapshotChanges()
      .pipe(
        map((offers) => {
          return offers.map((offer) => {
            const data = offer.payload.doc.data();
            const id = offer.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }

  /** retorna las facturas de un cliente */
  getInvoicesByClient(client: string) {
    return this.angularFirestore
      .collection<any>('invoices', (ref) => ref.where('user', '==', client))
      .get();
  }

  /** retorna todas las facturas */
  getAllInvoices() {
    return this.angularFirestore
      .collection<any>('invoices', (ref) => ref.orderBy('number', 'desc'))
      .snapshotChanges()
      .pipe(
        map((invoices) => {
          return invoices.map((invoice) => {
            const data = invoice.payload.doc.data();
            const id = invoice.payload.doc.id;
            return { id, ...data };
          });
        }),
        first(),
      )
      .toPromise();
  }

  /** actualiza los datos de una factura */
  updateInvoice(invoiceId: string, invoiceData: any): Promise<void> {
    return this.angularFirestore
      .collection<any>(`invoices`)
      .doc(invoiceId)
      .update(invoiceData);
  }

  /** consulta datos de estadisticas sobre el uso de la minibio */
  getMinibioStats(link: string) {
    return this.angularFirestore.collection<any>(this.statsRef).doc(link).get();
  }

  /** registra datos de estadisticas sobre el uso de la minibio */
  logMinibioStat(link: string, data: any) {
    return this.angularFirestore
      .collection<any>(this.statsRef)
      .doc(link)
      .set(data);
  }

  /** registra una estadistica al usuario */
  logStat(contactType: string, link: string) {
    this.getMinibioStats(link)
      .toPromise()
      .then((userLink) => {
        if (!userLink.exists) {
          // actualiza la bd
          this.logMinibioStat(link, this.newStatsData);
          return;
        }
        // nuevos datos
        const data = userLink.data();
        // agrega la fecha
        data[contactType].push({
          date: moment().tz('America/Bogota').format(),
        });
        // suma 1 al counter
        data[`${contactType}Counter`] += 1;
        // actualiza la bd
        return this.logMinibioStat(link, data);
      })
      .then(() => {
        console.log('stat registered');
      })
      .catch((error) => {
        // registra el error en Sentry
        this.errorMonitoringService.sendError(error, 'logStat');
        return false;
      });
  }

  /** consulta los datos del usuario por link de minibio */
  validateLink(link: string) {
    return this.angularFirestore
      .collection<any>('userProfile', (ref) =>
        ref.where('minibio.link', '==', link),
      )
      .get();
  }

  /** obtiene las ventas de un usuario */
  getSales(referralCode: string) {
    return this.angularFirestore
      .collection<any>('userProfile', (ref) =>
        ref
          .where('referred', '==', referralCode)
          .orderBy(`registerDate`, 'desc')
          .limit(10),
      )
      .snapshotChanges()
      .pipe(
        map((sales) => {
          return sales.map((sale) => {
            const docRef = sale.payload.doc;
            const data = sale.payload.doc.data();
            const id = sale.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
      );
  }

  /** obtiene mas ventas de un usuario */
  getMoreSales(referralCode: string, doc: any) {
    return this.angularFirestore
      .collection<any>('userProfile', (ref) =>
        ref
          .where('referred', '==', referralCode)
          .orderBy(`registerDate`, 'desc')
          .limit(5)
          .startAfter(doc),
      )
      .snapshotChanges()
      .pipe(
        map((sales) => {
          return sales.map((sale) => {
            const docRef = sale.payload.doc;
            const data = sale.payload.doc.data();
            const id = sale.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
      );
  }

  /** obtiene los usuarios activos de una empresa */
  getCompanyUsers(companyId: string) {
    return this.angularFirestore
      .collection<any>('companyUsers', (ref) =>
        ref
          .where('active', '==', true)
          .where('company', '==', companyId)
          .orderBy('name', 'asc'),
      )
      .snapshotChanges()
      .pipe(
        map((users) => {
          return users.map((user) => {
            const data = user.payload.doc.data();
            const id = user.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }

  /** obtiene los usuarios de una empresa en promesa */
  getCompanyUsersPromise(companyId: string) {
    return this.angularFirestore
      .collection<any>('companyUsers', (ref) =>
        ref
          .where('active', '==', true)
          .where('company', '==', companyId)
          .orderBy('name', 'asc'),
      )
      .snapshotChanges()
      .pipe(
        map((beneficiaries) => {
          return beneficiaries.map((beneficiary) => {
            const data = beneficiary.payload.doc.data();
            const id = beneficiary.payload.doc.id;
            return { id, ...data };
          });
        }),
        first(),
      )
      .toPromise();
  }

  /** elimina un usuario de empresa */
  deleteCompanyUser(userId: string) {
    return this.angularFirestore
      .collection<any>('companyUsers')
      .doc(userId)
      .delete();
  }

  /** obtiene todos los grupos de telegram */
  getAllCompanyUsers() {
    return this.angularFirestore
      .collection<any>('companyUsers')
      .snapshotChanges()
      .pipe(
        map((groups) => {
          return groups.map((group) => {
            const docRef = group.payload.doc;
            const data = group.payload.doc.data();
            const id = group.payload.doc.id;
            return { id, ...data, docRef };
          });
        }),
        first(),
      )
      .toPromise();
  }

  /** agrega un usuario a una empresa */
  addCompanyUser(userData: any): Promise<DocumentReference> {
    return this.angularFirestore.collection<any>('companyUsers').add(userData);
  }

  /** obtiene un usuario de empresa por id y empresa */
  getCompanyUserByIdAndCompany(userId: string, companyId: string) {
    return this.angularFirestore
      .collection<any>('companyUsers', (ref) =>
        ref.where('uid', '==', userId).where('company', '==', companyId),
      )
      .get();
  }

  /** agrega un usuario a una empresa */
  addFindyouPremiumUserInvitation(inviteData: any): Promise<DocumentReference> {
    return this.angularFirestore
      .collection<any>('findyouPremiumUsersInvitations')
      .add(inviteData);
  }

  /** abre modal de ventas premium */
  openPremiumModal(source: string): void {
    this.helperService.openPremiumModal(
      source,
      {
        mobile:
          'https://firebasestorage.googleapis.com/v0/b/jobcity-prod.appspot.com/o/rrss-metadata-img%2Fmodal-mobile-dic23.png?alt=media&token=4810f2a6-6e22-4b35-b878-2eec781ee745',
        pc: 'https://firebasestorage.googleapis.com/v0/b/jobcity-prod.appspot.com/o/rrss-metadata-img%2Fmodal-pc-dic23.png?alt=media&token=65ecbc0c-914c-47d8-b896-0adc1ec17c13',
      },
      {
        open: 'openPremiumModal',
        close: 'closePremiumModal',
      },
      [
        {
          analytics: {
            event: 'buyPremiumSales',
            params: {},
            register: true,
          },
          color: 'secondary',
          fill: 'solid',
          title: 'Activar',
          type: 'external',
          path: 'https://jobcity.com.co/producto/job-city-premium?utm_source=app&utm_medium=app&utm_campaign=modal',
        },
        {
          analytics: {
            event: 'contactPremiumSales',
            params: {},
            register: true,
          },
          color: 'light',
          fill: 'outline',
          title: 'Hablar con asesor',
          type: 'external',
          path: `https://wa.me/${environment.whatsapp}?text=¡Deseo sumarme a Job City Premium!`,
        },
      ],
      {
        pc_bottom: '25px',
        pc_left: '108px',
        mobile_bottom: '27px',
        mobile_left: '50px',
      },
    );
  }
}
