import { HelperService } from 'src/app/services/helper/helper.service';
import { Router } from '@angular/router';
import { NavParams, ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.page.html',
  styleUrls: ['./iframe.page.scss'],
})
export class IframePage implements OnInit {
  iframe: any;

  constructor(
    public navParams: NavParams,
    private modalController: ModalController,
    public router: Router,
    private helperService: HelperService,
  ) {
    // obtiene los parametros de la data
    this.iframe = this.navParams.get('iframe');
  }

  ngOnInit() {
    this.helperService.presentLoading();
    if (this.iframe) {
      this.loadIframe();
      setTimeout(() => {
        this.helperService.dismissLoading();
      }, this.iframe.loadTime);
      return;
    }
    this.modalController.dismiss().then(() => {
      this.helperService.presentMessageAlert(
        'Error',
        'No pudimos obtener los datos a renderizar',
      );
    });
  }

  // retorna la url del iframe
  loadIframe() {
    const site = this.iframe.url;
    const frame: any = document.getElementById('iframeWrapper');
    frame.src = site;
  }

  // cierra el modal
  closeModal(): void {
    this.modalController.dismiss().then(() => {
      if (this.iframe.closeAlert) {
        this.helperService.presentMessageAlert(
          this.iframe.closeAlert.title,
          this.iframe.closeAlert.description,
        );
      }
    });
  }
}
