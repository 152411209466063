import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { FunnelGuardService } from './../../services/funnel-guard/funnel-guard.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.page.html',
  styleUrls: ['./confirm.page.scss'],
})
export class ConfirmPage implements OnInit {
  image = ''; // contiene la imagen a mostrar
  title = ''; // contiene el titulo a mostrar
  subtitle = ''; // contiene el subtitulo a mostrar
  buttons = ''; // contiene los botones de redireccion

  constructor(
    public navParams: NavParams,
    private modalController: ModalController,
    public router: Router,
    private funnelGuardService: FunnelGuardService,
  ) {
    // obtiene los parametros de la navegación
    this.image = this.navParams.get('image');
    this.title = this.navParams.get('title');
    this.subtitle = this.navParams.get('subtitle');
    this.buttons = this.navParams.get('buttons');
  }

  ngOnInit() {}

  closeModal(button: any): void {
    this.modalController.dismiss().then(() => {
      // abre enrutamiento local
      if (button.type === 'internal') {
        this.router.navigate([`/${button.path}`]).then(() => {
          this.funnelGuardService.serviceCreated = false;
        });
      }
      // abre enrutamiento externo
      if (button.type === 'external') {
        this.funnelGuardService.serviceCreated = false;
        window.open(`${button.path}`);
      }
      // abre enrutamiento externo
      if (button.type === 'execute') {
        button.execute();
      }
    });
  }
}
