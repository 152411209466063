import { HelperService } from 'src/app/services/helper/helper.service';
import { UserDataService } from './../user-data/user-data.service';
import { Observable } from 'rxjs';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CompanyGuardService {
  constructor(
    private userDataService: UserDataService,
    private router: Router,
    private helperService: HelperService,
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<Observable<boolean> | boolean> {
    const userData = await this.userDataService.getUser();
    if (userData) {
      const role = await this.userDataService.getProfileByUid(userData.uid);
      if (role && role.publisher) {
        return true;
      }
      this.router.navigate(['/findyou']).then(() => {
        this.helperService.presentMessageAlert(
          'Mensaje',
          'No tienes los permisos suficientes para realizar esta operación.',
        );
      });
      return false;
    }
  }
}
