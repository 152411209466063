import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private contactRef = 'contactData';

  constructor(private angularFirestore: AngularFirestore) {}

  // agrega un servicio
  addContact(contactData: any): Promise<DocumentReference> {
    return this.angularFirestore
      .collection<any>(this.contactRef)
      .add(contactData);
  }
}
