import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorMonitoringService {
  constructor() {}

  /** envia el error a Sentry */
  sendError(error: any, method: string) {
    if (environment.production) {
      Sentry.withScope((scope) => {
        scope.setTag('method', method);
        scope.setTag('proactiveError', 'proactiveError');
        Sentry.captureException(error.originalError || error);
      });
    } else {
      console.error(error);
    }
  }

  /** define el usuario al que pertenece el error */
  setUserContext(user: any) {
    if (user) {
      Sentry.setUser(user);
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }
}

/** retorna la instancia de error handler que manejara los errores */
export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return Sentry.createErrorHandler({
      showDialog: false,
      logErrors: true,
    });
  }
  return new ErrorHandler();
}
