import { Injectable } from '@angular/core';
import { UserDataService } from '../user-data/user-data.service';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private userDataService: UserDataService,
    private router: Router,
    private helperService: HelperService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    if (this.userDataService.getUser()) {
      return true;
    }

    return this.userDataService.currentUserObservable.pipe(
      take(1),
      map((user: any) => {
        return !!(user && user.emailVerified); // lo mapea a booleano
      }),
      tap((logged) => {
        if (!logged) {
          const deniedUrl = [];
          next.url.forEach((url, index) => {
            if (index === 0) {
              deniedUrl.push(`/${url.path}`);
            } else {
              deniedUrl.push(url.path);
            }
          });
          this.userDataService.saveUrlDeniedLogin(deniedUrl);
          this.router.navigate(['']).then(() => {
            this.helperService.presentMessageAlert(
              'Mensaje',
              'Ingresa o Regístrate para acceder a este contenido',
            );
          });
        }
      }),
    );
  }
}
