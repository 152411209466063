<ion-content class="ion-no-padding">
  <ion-fab
    vertical="top"
    horizontal="start"
    slot="fixed"
    (click)="closeLogin()">
    <ion-fab-button size="small">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-grid class="grid-wraper">
    <ion-row class="login-wraper">
      <!-- Header -->
      <ion-col
        size="10"
        offset="1"
        size-md="8"
        offset-md="2"
        class="header ion-text-center">
        <img
          class="tw-mx-auto tw-w-16"
          src="assets/imgs/logo-light.png"
          alt="Logo Job City" />
        <h3 class="primary-text tw-mt-4 tw-mb-2.5">Bienvenido(a)</h3>
      </ion-col>

      <!-- Formulario de Login -->
      <ion-col
        size="10"
        offset="1"
        size-md="8"
        offset-md="2"
        *ngIf="isLogin">
        <form
          (ngSubmit)="login()"
          [formGroup]="loginForm">
          <!-- email -->
          <ion-item class="form-item">
            <ion-input
              name="email"
              type="email"
              formControlName="email"
              placeholder="Email"
              class="form-field"></ion-input>
          </ion-item>
          <!-- password -->
          <ion-item class="form-item">
            <ion-input
              name="password"
              id="passw"
              type="password"
              formControlName="password"
              placeholder="Contraseña"
              class="form-field passw"></ion-input>
            <ion-icon
              name="eye"
              (click)="showTextInput()"
              *ngIf="eye"
              class="show-password-button"></ion-icon>
            <ion-icon
              name="eye-off"
              (click)="showPasswordInput()"
              *ngIf="eyeOff"
              class="show-password-button"></ion-icon>
          </ion-item>
          <!-- botones -->
          <ion-button
            type="submit"
            expand="block"
            [disabled]="!loginForm.valid"
            *ngIf="!loadingButton"
            class="form-button">
            Ingresar
          </ion-button>
          <ion-button
            type="submit"
            expand="block"
            fill="outline"
            *ngIf="loadingButton"
            class="form-button">
            Ingresando <ion-spinner name="dots"></ion-spinner>
          </ion-button>
          <h5
            class="tw-cursor-pointer tw-text-sm hover:tw-underline primary-text ion-text-center tw-mt-4 tw-mb-2.5"
            (click)="recoveryPassword()">
            ¿Olvidaste tu contraseña?
          </h5>
        </form>
      </ion-col>

      <!-- Formulario de registro -->
      <ion-col
        size="10"
        offset="1"
        size-md="8"
        offset-md="2"
        class="ion-text-center"
        *ngIf="!isLogin">
        <form [formGroup]="registroForm">
          <!-- Nombre -->
          <ion-item class="form-item">
            <ion-input
              name="name"
              type="text"
              placeholder="Nombre"
              class="form-field"
              formControlName="name"></ion-input>
          </ion-item>
          <!-- Apellido -->
          <ion-item class="form-item">
            <ion-input
              name="apellido"
              type="text"
              placeholder="Apellido"
              class="form-field"
              formControlName="surname"></ion-input>
          </ion-item>
          <!-- Celular -->
          <ion-item class="form-item">
            <ion-input
              name="mobile"
              type="number"
              placeholder="Celular"
              class="form-field"
              formControlName="mobile"
              (ionInput)="validateNumero($event)"></ion-input>
          </ion-item>
          <!-- Email -->
          <ion-item class="form-item">
            <ion-input
              name="email"
              type="email"
              placeholder="Email"
              class="form-field"
              formControlName="email"></ion-input>
          </ion-item>
          <!-- Password -->
          <ion-item class="form-item">
            <ion-input
              name="password"
              id="passw"
              type="password"
              formControlName="password"
              placeholder="Contraseña"
              class="form-field passw"></ion-input>
            <ion-icon
              name="eye"
              (click)="showTextInput()"
              *ngIf="eye"
              class="show-password-button"></ion-icon>
            <ion-icon
              name="eye-off"
              (click)="showPasswordInput()"
              *ngIf="eyeOff"
              class="show-password-button"></ion-icon>
          </ion-item>
        </form>
        <!-- T&C -->
        <div class="form-terminos">
          <ion-checkbox
            [(ngModel)]="clientChecked"
            class="ion-no-padding"></ion-checkbox>
          <ion-label class="label-terminos"
            >Al registrarte aceptas nuestros
            <a
              href="https://jobcity.com.co//terminos-y-condiciones?utm_source=app&utm_medium=app&utm_campaign=app"
              target="_blank"
              >términos y condiciones</a
            >
            y la
            <a
              href="https://jobcity.com.co/politica-de-privacidad?utm_source=app&utm_medium=app&utm_campaign=app"
              target="_blank"
              >política de privacidad</a
            ></ion-label
          >
        </div>
        <!-- ActionButton -->
        <ion-button
          (click)="signUp()"
          expand="block"
          [disabled]="!registroForm.valid || !clientChecked"
          class="form-button"
          *ngIf="!loadingButton">
          Registrarme
        </ion-button>
        <ion-button
          expand="block"
          fill="outline"
          *ngIf="loadingButton"
          class="form-button">
          Registrando <ion-spinner name="dots"></ion-spinner>
        </ion-button>
      </ion-col>

      <!-- contactar a soporte -->
      <ion-col
        size="10"
        offset="1"
        size-md="8"
        offset-md="2"
        class="ion-text-center">
        <ion-chip
          color="tertiary"
          (click)="contactSupport()">
          <ion-label><b>Contactar a soporte</b></ion-label>
          <ion-icon name="logo-whatsapp"></ion-icon>
        </ion-chip>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
