import { ConfirmPage } from './../confirm/confirm.page';
import { OpenModalGuardService } from './../../services/open-modal-guard/open-modal-guard.service';
import { OffersService } from 'src/app/services/offers/offers.service';
import { HelperService } from './../../services/helper/helper.service';
import { ModalController } from '@ionic/angular';
import { SubscriptionsService } from './../../services/subscriptions/subscriptions.service';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring/error-monitoring.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.page.html',
  styleUrls: ['./onboarding.page.scss'],
})
export class OnboardingPage implements OnInit, OnDestroy {
  /** contiene los datos del usuario */
  user: any;
  /** suscripción a los datos del usuario */
  userSubscriptionState: Subscription;
  /** Contiene las suscripciones a ofertas */
  subscriptions = [];
  /** Listado de ofertas */
  offers = [];
  /** Indica si la ruta ya culmino */
  finished = false;

  constructor(
    private subscriptionsService: SubscriptionsService,
    private userDataService: UserDataService,
    private errorMonitoringService: ErrorMonitoringService,
    private modalController: ModalController,
    private helperService: HelperService,
    private offersService: OffersService,
    private openModalGuardService: OpenModalGuardService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.userSubscriptionState =
      this.userDataService.userStateObservable.subscribe(
        (user) => {
          this.user = user;
          if (this.user) {
            const url = this.router.url;
            if (url.includes('/oferta/')) {
              // cierra el onboarding si es el detalle de una oferta
              setTimeout(() => {
                this.closeModal('', '');
              }, 500);
            }
            // si el onboarding es personas
            if (
              this.user.onboardingType &&
              this.user.onboardingType === 'person'
            ) {
              // obtiene las suscripiones
              this.getUserSubscription();
            }
            // si el onboarding es empresas
            if (
              this.user.onboardingType &&
              this.user.onboardingType === 'company'
            ) {
              // obtiene las ofertas
              this.getOffers();
            }
          }
        },
        (error) => {
          this.errorMonitoringService.sendError(error, 'getUser');
        },
      );
  }

  /** Obtiene las suscripciones a notificaciones */
  getUserSubscription() {
    this.subscriptionsService.getSubscriptions(this.user.uid).subscribe(
      (subscriptions) => {
        this.subscriptions = subscriptions;
      },
      (error) => {
        // registra el error
        this.errorMonitoringService.sendError(error, 'getSubscriptions');
      },
    );
  }

  /** Actualiza el tipo de onboarding */
  selectOnboardingType(type: string): void {
    const updateData = {
      onboardingType: type,
    };
    this.userDataService
      .updateProfile(updateData, this.user.uid)
      .catch((error) => {
        this.errorMonitoringService.sendError(error, 'updateProfile');
        this.helperService.presentMessageAlert(
          'Mensaje',
          `No pudimos actualizar tu usuario, inténtalo de nuevo`,
        );
      });
  }

  /** Obtiene las ofertas de una empresa */
  getOffers() {
    // consulta las ofertas
    this.offersService
      .getOffersByUserAndStatus(this.user.uid, 'published')
      .subscribe(
        (offers) => {
          this.offers = offers;
        },
        (error) => {
          // registra el error
          this.errorMonitoringService.sendError(error, 'getOffers');
        },
      );
  }

  /** Valida cual icono se debe mostrar */
  validateStatus(property: string): boolean {
    // Minibio
    if (property === 'minibio') {
      if (this.user.minibio) {
        return true;
      } else {
        return false;
      }
    }
    // Ofertas
    if (property === 'searcher') {
      if (this.user.searcher?.joinGroup) {
        return true;
      } else {
        return false;
      }
    }
    // Find Me
    if (property === 'findme') {
      if (this.subscriptions.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    // Ruta
    if (property === 'route') {
      if (this.user.showedRoute) {
        return true;
      } else {
        return false;
      }
    }
    // Create Company
    if (property === 'create-company') {
      if (this.user.publisher) {
        return true;
      } else {
        return false;
      }
    }
    // Crear Ofertas
    if (property === 'offers') {
      if (this.offers.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    // Ver Ofertas
    if (property === 'show-offers') {
      if (this.user.publisher && this.user.publisher.showedOffers) {
        return true;
      } else {
        return false;
      }
    }
    // Ver empresa
    if (property === 'show-companies') {
      if (this.user.publisher && this.user.publisher.showedCompanies) {
        return true;
      } else {
        return false;
      }
    }
  }

  /** Calcula el progreso total del onboarding */
  calculateProgress(): any {
    let progress = 0;
    /* Persona */
    if (this.user.onboardingType === 'person') {
      // Minibio
      if (this.validateStatus('minibio')) {
        progress += 25;
      }
      // Ofertas
      if (this.validateStatus('searcher')) {
        progress += 25;
      }
      // Findme
      if (this.validateStatus('findme')) {
        progress += 25;
      }
      // Route
      if (this.validateStatus('route')) {
        progress += 25;
      }
    }
    /* Empresa */
    if (this.user.onboardingType === 'company') {
      // Create company
      if (this.validateStatus('create-company')) {
        progress += 25;
      }
      // Offers
      if (this.validateStatus('offers')) {
        progress += 25;
      }
      // Show Offers
      if (this.validateStatus('show-offers')) {
        progress += 25;
      }
      // Show Companies
      if (this.validateStatus('show-companies')) {
        progress += 25;
      }
    }
    // valida si la ruta ya culmino
    if (!this.finished && progress == 100) {
      this.finished = true;
      const updateData = {
        onboarding: true,
      };
      this.userDataService
        .updateProfile(updateData, this.user.uid)
        .then(() => {
          setTimeout(() => {
            this.openConfirmationModal();
          }, 3000);
        })
        .catch((error) => {
          this.errorMonitoringService.sendError(error, 'updateProfile');
          this.helperService.presentMessageAlert(
            'Mensaje',
            `No pudimos actualizar tu usuario, inténtalo de nuevo`,
          );
        });
    }
    return progress;
  }

  // abre el modal de confirmación
  async openConfirmationModal() {
    // indica que se debe bloquear/desbloquear la navegacion
    this.openModalGuardService.setModalOpen(true);
    // crea el modal con el chat
    const confirmModal = await this.modalController.create({
      component: ConfirmPage,
      cssClass: 'confirm-modal',
      componentProps: {
        image: 'assets/imgs/fireworks.png',
        title: '¡Hemos Culminado!',
        subtitle: 'Disfruta tu experiencia en la comunidad',
        buttons: [
          {
            title: 'Continuar',
            path: '',
            type: 'execute',
            execute: () => {
              this.closeModal('', '');
            },
          },
        ],
      },
      backdropDismiss: false,
    });
    confirmModal.onDidDismiss().then(() => {
      // indica que se debe bloquear/desbloquear la navegacion
      this.openModalGuardService.setModalOpen(false);
    });
    // presenta el modal con el mapa
    return await confirmModal.present();
  }

  /** Cierra el modal y envia la data */
  closeModal(subMenu: string, step: string): void {
    const data = {
      subMenu,
      step,
    };
    this.modalController.dismiss(data);
  }

  // on destroy
  ngOnDestroy(): void {
    if (this.userSubscriptionState) {
      this.userSubscriptionState.unsubscribe();
    }
  }
}
