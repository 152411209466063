import { OnboardingPopoverComponent } from './onboarding-popover/onboarding-popover.component';
import { OptionCardComponent } from './option-card/option-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [OptionCardComponent, OnboardingPopoverComponent],
  exports: [OptionCardComponent, OnboardingPopoverComponent],
  imports: [RouterModule, CommonModule, FormsModule, IonicModule],
})
export class ComponentsModule {}
